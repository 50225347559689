<template>
  <div id="dashboard">
    <b-row class="m-0" >
          <b-col sm="12" md="5" lg="4" xl="3">
            <b-row class="m-0">
              <b-col class="card-welcome">
                <h2>
                  Tableau de bord de Gestion
                </h2>
                <b-link :to="'/dashboard-analytics'" tag="a" class="d-flex align-items-center primary">
                  <span class="material-icons-outlined">chevron_left</span>
                  Vue analytique
                </b-link> 
                <vs-divider color="#cccccc" class="mt-1 mb-1"></vs-divider>
                <h4 class="text-primary">Bonjour{{ userData ? " " + userData.firstName : "" }},</h4>
                <p>Vous avez <span>aucune action</span> à effectuer aujourd'hui</p>
              </b-col>
            </b-row>
            <b-row style="margin-top: 12px;">
              <b-col cols="12" class="card-action-large">
                <b-link :to="'/new-tenant'" class="action-content w-100 icon-invoice-check">  
                  <div class="d-flex w-100 justify-content-between">
                    <div class="title text-indigo">
                      <span>Factures</span></br>à valider
                    </div>
                    <div class="number text-purple">5</div>
                  </div>
                  <div class="d-flex w-100 align-items-center justify-content-end">
                    <div class="pin">12 factures</div>
                    <div class="pin">97 000 €<sup>HT/HC</sup></div>
                  </div>
                </b-link>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col cols="12" class="card-action-large">
                <b-link :to="'/new-tenant'" class="action-content w-100 icon-charge-check">  
                  <div class="d-flex w-100 justify-content-between">
                    <div class="title text-warning">
                      <span>FRAIS & CHARGES</span></br>à répartir
                    </div>
                    <div class="number text-warning">8</div>
                  </div>
                  <div class="d-flex w-100 align-items-center justify-content-end">
                    <div class="pin">12 factures</div>
                    <div class="pin">97 000 €<sup>HT/HC</sup></div>
                  </div>
                </b-link>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col cols="12" class="card-action-large">
                <b-link :to="'/new-tenant'" class="action-content w-100 icon-lease-renew">  
                  <div class="d-flex w-100 justify-content-between">
                    <div class="title text-danger">
                      <span>BAUX</span></br>à renouveler
                    </div>
                    <div class="number text-danger">1</div>
                  </div>
                  <div class="d-flex w-100 align-items-center justify-content-end">
                    <div class="pin">7 000 €<sup>HT/HC</sup></div>
                  </div>
                </b-link>
              </b-col>
            </b-row>
            <b-row class="mt-1">
              <b-col cols="4" class="card-action">
                <b-link :to="'/new-tenant'" class="action-content icon-add-tenant">  
                  <div class="">Créer</br>un <span>locataire</span></div>
                </b-link>
              </b-col>
              <b-col cols="4" class="card-action">
                <b-link :to="'/directory/new-company/clients/1'" class="action-content icon-add-lease">  
                  <div>Créer</br>un <span>bail</span></div>
                </b-link>
              </b-col>
              <b-col cols="4" class="card-action">
                <b-link :to="'/directory/new-contact'" class="action-content icon-add-document">  
                  <div>Enregistrer</br>un <span>document</span></div>
                </b-link>
              </b-col>
              <b-col cols="4" class="card-action">
                <b-link :to="'/new-collaborator'" class="action-content icon-add-charge text-danger">  
                  <div>Saisir</br><span>une charge</span></div>
                </b-link>
              </b-col>
              <b-col cols="4" class="card-action">
                <b-link @click="showPlanning"  class="action-content icon-add-invoice">  
                  <div>Créer</br>une <span>facture</span></div>
                </b-link>
              </b-col>
              <b-col cols="4" class="card-action">
                <b-link @click="showPlanning"  class="action-content icon-add-payment">  
                  <div>Enregistrer</br>un <span>règlement</span></div>
                </b-link>
              </b-col>
            </b-row>
          </b-col>

          <b-col sm="12" md="7" lg="8" xl="9" class="p-1 bg-dashboard-gestion">            
            <b-row>
              <b-col cols="12">
                <p class="h4 pt-1 mb-3">Ce tableau de bord vous propose des suggestions de traitement automatisé à partir de l’analyse quotidienne de vos données. Cliquez sur un encart à gauche pour obtenir la liste des traitements.</p>
                <div class="d-flex mb-2">
                  <div class="icon mr-1">
                    <span class="material-icons-outlined text-purple" style="font-size: 50px;">
                      task
                    </span>
                  </div>
                  <div class="text">
                    <h4 class="text-purple">Suggestion des factures à émettre</h4>
                    <p>Le logiciel analyse vos baux afin de vous proposer les factures à émettre à vos locataires selon la périodicité prévue.</p>
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="icon mr-1">
                    <span class="material-icons-outlined text-warning" style="font-size: 50px;">
                      difference
                    </span>
                  </div>
                  <div class="text">
                    <h4 class="text-warning">Suggestion des factures à valider</h4>
                    <p>Le logiciel vous propose automatiquement de traiter les factures fournisseur reçuent via l’application Yooz, afin d’effectuer la répartition des charges.</p>
                  </div>
                </div>
                <div class="d-flex mb-2">
                  <div class="icon mr-1">
                    <span class="material-icons-outlined text-danger" style="font-size: 50px;">
                      event_repeat
                    </span>
                  </div>
                  <div class="text">
                    <h4 class="text-danger">Rappel des baux à renouveler</h4>
                    <p>Le logiciel vous indique les baux arrivant à expiration dans les 6 prochains mois.</p>
                  </div>
                </div>

              </b-col>
            </b-row>
          </b-col>

    </b-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { BCard, BCardText, BLink } from 'bootstrap-vue'
import { latLng } from "leaflet";
import { LMap, LTileLayer, LMarker, LPopup, LTooltip } from "vue2-leaflet";
import VueApexCharts from 'vue-apexcharts'

import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
var dayjs = require('dayjs')
var quarterOfYear = require('dayjs/plugin/quarterOfYear')
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
var advanced = require('dayjs/plugin/advancedFormat')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.tz.setDefault('Europe/Paris')
dayjs.extend(advanced)
dayjs.extend(quarterOfYear)
const config = {
  direction: 'ltr',
  format: 'dd/mm/yyyy',
  separator: ' - ',
  applyLabel: 'Appliquer',
  cancelLabel: 'Annuler',
  weekLabel: 'S',
  customRangeLabel: 'Période personnalisée',
  daysOfWeek: ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'],
  monthNames: [
    'Janvier',
    'Février',
    'Mars',
    'Avril',
    'Mai',
    'Juin',
    'Juillet',
    'Août',
    'Septembre',
    'Octobre',
    'Novembre',
    'Décembre'
  ],
  firstDay: 0
}
var defaultRange = {
  'Ce mois-ci': [
    new Date(
      dayjs()
        .startOf('month')
        .hour(12)
    ),
    new Date(dayjs().endOf('month'))
  ],
  'Ce trimestre': [
    new Date(
      dayjs()
        .startOf('quarter')
        .hour(12)
    ),
    new Date(dayjs().endOf('quarter'))
  ],
  'Cette annéee': [
    new Date(
      dayjs()
        .startOf('year')
        .hour(12)
    ),
    new Date(dayjs().endOf('year'))
  ],
  'Année prochaine': [
    new Date(
      dayjs()
        .add(1, 'year')
        .startOf('year')
        .hour(12)
    ),
    new Date(
      dayjs()
        .add(1, 'year')
        .endOf('year')
    )
  ],
  'Année dernière': [
    new Date(
      dayjs()
        .subtract(1, 'year')
        .startOf('year')
        .hour(12)
    ),
    new Date(
      dayjs()
        .subtract(1, 'year')
        .endOf('year')
    )
  ]
}

export default {
  components: {
    BCard,
    BCardText,
    BLink,
    ApexCharts,
    LMap,
    LTileLayer,
    LMarker,
    LPopup,
    LTooltip,
    VueApexCharts,
    DateRangePicker
  },
  data() {
    return {
      defaultRange: defaultRange,
      config: config,
      
      statForm:{
        dateRange: {
          startDate: dayjs().subtract(7, 'day').format("YYYY-MM-DD"),
          endDate:  dayjs().add(30,'day').format("YYYY-MM-DD")
        },
      },

      zoom: 13,
      center: latLng(47.41322, -1.219482),
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      attribution:
        '&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors',
      withPopup: latLng(47.41322, -1.219482),
      withTooltip: latLng(47.41422, -1.250482),
      currentZoom: 11.5,
      currentCenter: latLng(47.41322, -1.219482),
      showParagraph: false,
      mapOptions: {
        zoomSnap: 0.5
      },
      showMap: true,

      userData : JSON.parse(localStorage.getItem('login'))?.login?.userInfo,

      statsFromPlanning:{
        loaded:false,
        series: [],
        chartOptions: {
          chart: {
            type: 'bar',
            height: 300,
            stacked: true,
            toolbar: {
              show: true
            },
            zoom: {
              enabled: true
            }
          },
          responsive: [{
            breakpoint: 480,
            options: {
              legend: {
                position: 'bottom',
                offsetX: -10,
                offsetY: 0
              }
            }
          }],
          plotOptions: {
            bar: {
              horizontal: false,
              borderRadius: 5
            },
          },
          xaxis: {
            type: 'datetime',
            categories: [],
            labels: { hideOverlappingLabels: true, datetimeFormatter: { day: 'dd/MM', } }
          },
          legend: {
            position: 'bottom'
          },
          fill: {
            opacity: 1
          }
        }
      },
      statsbyEventType:{
        loaded:false,
        series:[],
        chartOptions: {
          chart: {
            type: 'pie',
          },
          labels: [],
          colors: [],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 200
              },
              legend: {
                position: 'bottom'
              }
            }
          }],
          legend: {
            position: 'bottom'
          },
          plotOptions: {
            pie: {
              expandOnClick: false
            }
          },
          dataLabels: {
            enabled: false,
            formatter: function (val) {
              return val + "J"
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: "Total",
                  value: 122
                }
              }
            }
          }
        },
      },

      dataStatsEventTypesByDay:[
        { 
          eventTypeId:1,
          color:"#0c3571",
          name:"Développement",
          data:[0,6,6,6,6, 0,0, 4,4,4,4,4, 0,0, 5,5,5,5,5, 0,0, 6,6,4,2,2]
        },
        { 
          eventTypeId:2,
          color:"#BDC3C8",
          name:"Formation",
          data:[0,0,0,0,0, 0,0, 2,2,2,2,2, 0,0, 0,0,0,0,0, 0,0, 0,0,0,0,0]
        },
        { 
          eventTypeId:3,
          color:"#E84B3C",
          name:"Arrêt Maladie",
          data:[0,0,0,0,0, 0,0, 0,0,0,0,0, 0,0, 0,0,0,0,0, 0,0, 0,0,0,0,0]
        },
        { 
          eventTypeId:4,
          color:"#F2C511",
          name:"Congés Payés",
          data:[0,0,0,0,0, 0,0, 0,0,0,0,0, 0,0, 1,1,1,1,1, 0,0, 0,0,0,0,0]
        }
      ],
      dataStatsEventTypesGlobal:{
        labels: ["Développement","Formation", "Arrêt Maladie", "Congés Payés"],
        colors: ["#0c3571", "#BDC3C8", "#E84B3C", "#F2C511"],
        data:[87,10,0,5]
      }
      
    };
  },
  computed: {
    ...mapGetters([
      "collaboratorsList",
      "planningViewsList",
      "statsEventTypesGlobal",
      "statsEventTypesByDay",
      "datePeriode",
    ]),
    collaboratorListMapped () {
        return this.$store.getters.collaboratorsList.map(elem => {
            return {
              id:elem.id,
              position: {lat: elem.latitude, lng:  elem.longitude},
            }
        })
    },    
    statsEventTypesByDayMapped () {
        return this.$store.getters.statsEventTypesByDay.map(elem => {
            return {
              color:elem.color,
              eventTypeId: elem.eventTypeId,
              name: elem.name,
              data: elem.numberEvents,
            }
        })
    }   
  },
  // watch: {
  //   datePeriode: function(val, oldVal){
  //     console.log(val, oldVal)
  //     this.statsFromPlanning.chartOptions.xaxis.categories=val
  //     this.$nextTick(() => {
  //       this.statsFromPlanning.loaded = true
  //     });
  //   },
  // },
  mounted(){
    this.setDates(this.statForm.dateRange)

    // this.statsbyEventType.series=this.dataStatsEventTypesGlobal.data
    // this.statsbyEventType.chartOptions.labels=this.dataStatsEventTypesGlobal.labels
    // this.statsbyEventType.chartOptions.colors=this.dataStatsEventTypesGlobal.colors

    // this.statsFromPlanning.series=this.dataStatsEventTypesByDay
    // this.statsFromPlanning.chartOptions.xaxis.categories=this.dataPeriode

    // this.$nextTick(() => {
    //    this.statsbyEventType.loaded = true
    //    this.statsFromPlanning.loaded = true
    // });
  },
  methods: {
    ...mapActions([
      "fetchStatsEventTypesGlobal",
	    "fetchStatsEventTypesByDay",
	    "fetchDatePeriode"
    ]),
    async setDates (dates) {
      console.log("setDates", dates)
      this.statsFromPlanning.loaded = false
      await this.$store.dispatch("fetchDatePeriode", { startDate:new Date(dates.startDate).toISOString(), endDate:new Date(dates.endDate).toISOString()}).then(res => {
        this.$nextTick(() => {
          this.statsFromPlanning.chartOptions.xaxis.categories=this.datePeriode
        })
      })
      await this.$store.dispatch("fetchStatsEventTypesByDay", { startDate:new Date(dates.startDate).toISOString(), endDate:new Date(dates.endDate).toISOString()}).then(res => {
        this.$nextTick(() => {
          this.statsFromPlanning.series = this.statsEventTypesByDayMapped
        });
      })
      this.statsFromPlanning.loaded = true

      this.statsbyEventType.loaded = false
      await this.$store.dispatch("fetchStatsEventTypesGlobal", { startDate:new Date(dates.startDate).toISOString(), endDate:new Date(dates.endDate).toISOString()}).then(res => {
        this.$nextTick(() => {
          console.log("fetchStatsEventTypesGlobal", this.statsEventTypesGlobal)
          this.statsbyEventType.series=this.statsEventTypesGlobal.numberEvents
          this.statsbyEventType.chartOptions.labels=this.statsEventTypesGlobal.name
          this.statsbyEventType.chartOptions.colors=this.statsEventTypesGlobal.color
          this.statsbyEventType.loaded = true
        });
      })
      

      if (dates.startDate != null && dates.endDate != null) {
        this.statForm.startDate = new Date(dates.startDate).toISOString()
        this.statForm.endDate = new Date(dates.endDate).toISOString()
      } else {
        this.statForm.startDate = ''
        this.statForm.endDate = ''
      }
    },

    showPlanning(){
      this.$router.push({ name: 'planning', params: { id: this.planningViewsList[0].id, title: "Planning : "+ this.planningViewsList[0].name, tips:"Planning : "+this.planningViewsList[0].name } })
    },

    zoomUpdate(zoom) {
      this.currentZoom = zoom;
    },
    centerUpdate(center) {
      this.currentCenter = center;
    },
    showLongText() {
      this.showParagraph = !this.showParagraph;
    },
    innerClick() {
    }
  }
}
</script>
<style>

</style>
